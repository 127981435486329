.submit {
  background-color: black;
  border-radius: 5px;
  padding: 20px;
}
.bg-overlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0,0.5);
}
